import { render, staticRenderFns } from "./PageTemplateForm.vue?vue&type=template&id=124ea9cd&scoped=true&"
import script from "./PageTemplateForm.vue?vue&type=script&lang=js&"
export * from "./PageTemplateForm.vue?vue&type=script&lang=js&"
import style0 from "./PageTemplateForm.vue?vue&type=style&index=0&id=124ea9cd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "124ea9cd",
  null
  
)

export default component.exports